import React from 'react'
import PropTypes from 'prop-types';

import {
  StyledBody
} from './styles/Body2.styles'

const Body2 = ({ children, as, ...props }) => (
  <StyledBody
    as={as}
    {...props}
  >
    {children}
  </StyledBody>
)

Body2.propTypes = {
  children: PropTypes.node,
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
    PropTypes.object
  ]),
  fontSize: PropTypes.array
}

Body2.defaultProps = {
  fontSize: [14],
  as: 'p'
}

export default Body2
