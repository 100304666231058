import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledH5
} from './styles/H5.styles'

const H5 = ({ children, ...props }) => (
  <StyledH5
    as='h5'
    {...props}
  >
    {children}
  </StyledH5>
)

H5.propTypes = {
  children: PropTypes.node.isRequired
}

H5.defaultProps = {
  fontSize: [23.7]
}

export default H5
