import React from 'react'
import { Link } from 'gatsby'
// import AnchorLink from './AnchorLink'

import {
  menu,
  MenuItem,
  DropdownContent,
  ContentBridge
} from './styles/Navbar.styles'
import { Paper } from '@material-ui/core';

const Navbar = ({
  items
}) => (
  <div css={menu}>
    {items.map(item => (
      <MenuItem
        key={item.label}
      >
        <Link
          to={item.link || '/'}
        >
          {item.label}
        </Link>
        {item.nested && (
          <ContentBridge>
            <DropdownContent>
              <Paper
                elevation={9}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '8px 0'
                }}
              >
                {item.nested.map(nestedItem => (
                  <Link
                    key={nestedItem.label}
                    to={nestedItem.link || '/'}
                  >
                    {nestedItem.label}
                  </Link>
                ))}
              </Paper>
            </DropdownContent>
          </ContentBridge>
        )}
      </MenuItem>
    ))}
  </div>
)

export default Navbar
