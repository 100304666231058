import { Link, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import { css } from '@emotion/core'

import Navbar from './Navbar'
import Logo from '../images/logoHostdime.svg'
import LogoMobile from '../images/logoHostdimeMobile.svg'
// import ds from '../theme'
// import Search from './Search'
import Avatar from './Avatar'
import { mq, Container } from './styles/Layout.styles'

import {
  head,
  wrapper,
  Toggle,
  Burguer,
  listItem,
  logoLarge,
  logoSmall
} from './styles/Header.styles'
import NestedListItem from './NestedListItem'

import About from '@material-ui/icons/Widgets'
import ThumbsUp from '@material-ui/icons/ThumbUpAlt'
import Products from '@material-ui/icons/Style'
import Prices from '@material-ui/icons/Loyalty'
import Structure from '@material-ui/icons/Cloud'
import Clients from '../images/icons/client-24-px.svg'
import Suport from '@material-ui/icons/Favorite'
import Blog from '../images/icons/border-color-24-px.svg'
import Contact from '@material-ui/icons/Chat'
import Tools from '@material-ui/icons/Build'
import ActiveSearch from './ActiveSearch'
import SearchDrawerItems from './SearchDrawerItems/SearchDrawerItems'
import SearchCancel from './SearchCancel'

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    position: 'absolute',
    top: '72px',
    height: 'calc(100% - 72px)'
  },
  searchDrawerPaper: {
    position: 'absolute',
    top: '72px',
    height: '450px'
  },
  list: {
    width: 296
  },
  root: {
    position: 'fixed'
  },
  searchRoot: {
    backgroundColor: 'transparent'
  }
}))

const navbarItems = [
  {
    label: 'Sobre',
    link: '/sobre',
    icon: About
  },
  {
    label: 'Por que a HostDime?',
    link: '/por-que-a-hostdime',
    icon: ThumbsUp
  },
  {
    label: 'Produtos',
    icon: Products,
    nested: [
      {
        label: 'Servidores Dedicados',
        link: '/servidores-dedicados'
      },
      {
        label: 'Cloud Server Hostdime',
        link: '/cloud-server'
      },
      {
        label: 'Colocation Hostdime',
        link: '/colocation'
      },
      {
        label: 'Outros Produtos',
        link: '/outros-produtos'
      }
    ]
  },
  {
    label: 'Preços',
    icon: Prices,
    nested: [
      {
        label: 'Servidores Dedicados',
        link: '/servidores-dedicados-nordeste'
      },
      {
        label: 'Cloud Server HostDime',
        link: '/precos-cloud-server'
      },
      {
        label: 'Outlet',
        link: '/servidores-dedicados-outlet'
      }
    ]
  },
  {
    label: 'Estrutura',
    link: '/estrutura',
    icon: Structure
  },
  {
    label: 'Clientes',
    link: '/clientes',
    icon: Clients
  }
]

const drawerItems = [
  ...navbarItems,
  {
    label: 'Suporte',
    link: '/suporte',
    icon: Suport
  },
  {
    label: 'Blog',
    link: '/blog',
    icon: Blog
  },
  {
    label: 'Contato',
    link: '/contatos',
    icon: Contact
  },
  {
    label: 'Ferramentas',
    link: '/ferramentas',
    icon: Tools
  },
]

const Header = ({
  history
}) => {
  const classes = useStyles()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [isSearching, setIsSearching] = useState(false)

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setDrawerVisible(open)
  }

  const toggleSearching = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setIsSearching(open)
  }
  
  const navigateTo = to => {
    navigate(to)
    setDrawerVisible(false)
  }

  const sideList = (
    <div
      className={classes.list}
      role='presentation'
    >
      <List>
        {drawerItems.map((item, index) => {
          const Icon = item.icon
          return item.nested
            ? (
              <NestedListItem
                key={item.label}
                nested={item.nested}
                onClick={navigateTo}
              >
                <ListItemIcon>{<Icon /> || <InboxIcon />}</ListItemIcon>
                <ListItemText primary={item.label} />
              </NestedListItem>
            )
            : (
              <ListItem
                button
                key={item.label}
                onClick={() => navigateTo(item.link)}
                css={listItem}
              >
                <ListItemIcon>{<Icon /> || <InboxIcon />}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            )
          })}
      </List>
    </div>
  );

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 1500,
        width: '100%',
        top: 0,
        left: 0
      }}
    >
      <header
        css={[head]}
      >
        <Container
          css={wrapper}
          style={{
            maxWidth: '100%'
          }}
        >
          <Toggle
            onClick={() => setDrawerVisible(!drawerVisible)}
          >
            <Burguer
              className={drawerVisible && 'open'}
            >
              <span />
              <span />
              <span />
            </Burguer>
          </Toggle>
          <Link
            to='/'
            css={
              [css(
                mq({
                  display: [
                    isSearching ? 'none' : 'block',
                    isSearching ? 'none' : 'block',
                    'block'
                  ]
                })
              ),
                logoLarge
              ]
            }
          >
            <img src={Logo} title='HostDime Brasil' />
          </Link>
          <Link
            to='/'
            css={
              [css(
                mq({
                  display: [
                    isSearching ? 'none' : 'block',
                    isSearching ? 'none' : 'block',
                    'block'
                  ]
                })
              ),
                logoSmall
              ]
            }
          >
            <img src={LogoMobile} title='HostDime Brasil' />
          </Link>
          <div
            style={{
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
              flexGrow: isSearching && 1
            }}
          >
            {isSearching && (
              <ActiveSearch />
            )}
            {!isSearching && (
              <Navbar
                items={navbarItems}
                history={history}
              />
            )}
            {/* {!isSearching && (
              <Search
                style={{
                  marginRight: ds.space(3)
                }}
                onClick={() => setIsSearching(!isSearching)}
              />
            )} */}
            <SearchCancel
              css={
                css(
                  mq({
                    display: [
                      isSearching ? 'block' : 'none',
                    ]
                  })
                )
              }
              onClick={() => setIsSearching(false)}
            />
            <a
              href='https://core.hostdime.com.br/support/new'
            >
              <Avatar
                css={
                  css(
                    mq({
                      display: [
                        isSearching ? 'none' : 'block',
                      ]
                    })
                  )
                }
              />
            </a>
          </div>
        </Container>
        <Drawer
          open={isSearching}
          onClose={toggleSearching(false)}
          anchor='top'
          elevation={0}
          classes={{
            paper: classes.searchDrawerPaper,
            modal: classes.searchRoot
          }}
          PaperProps={{
            tabIndex: '10'
          }}
        >
          <SearchDrawerItems
            toggleDrawer={toggleSearching(false)}
          />
        </Drawer>
        <Drawer
          open={drawerVisible}
          onClose={toggleDrawer(false)}
          classes={{
            paper: classes.drawerPaper
          }}
          PaperProps={{
            tabIndex: '10',
            role: 'navigation'
          }}
        >
          {sideList}
        </Drawer>
      </header>
    </div>
  )
}

Header.propTypes = {
  history: PropTypes.object
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
