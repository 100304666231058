import React, { Fragment } from 'react'
import { css } from '@emotion/core'
import Call from '@material-ui/icons/Call'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import { Link, navigate } from 'gatsby'
import { generate } from 'shortid'

import Row from './Row'
import Column from './Column'
import Div from './Div'
import Subtitle from './SubTitle'
import H4 from './H4'
import Body2 from './Body2'
import Button from './Button'
import ds from '../theme'
import H6 from './H6'
import { mq } from './styles/Layout.styles'
import EnvioForm from '../components/EnvioForm'

const fields = [
  {
    label: 'Informe seu email',
    name: 'your-email',
    type: 'email',
    required: true
  }
]

const dots = css`
  height: 6px;
  width: 6px;
  background-color: #717278;
  border-radius: 50%;
  margin-left: 12px;
  margin-right: 12px;
`

const socialIcons = css`
  a {
    color: rgb(217, 222, 227);
  }
  a:hover {
    opacity: 0.8;
    transition: opacity 0.4s;
  }
  a:not(:last-child) {
    margin-right: ${ds.space(2)}
  }
`

const TelephoneContact = ({
  number,
  region,
  callTo
}) => (
  <>
    <Button
      variant='mediumEmphasis'
      outlined
      schema='whiteTransparent'
      color='white'
      mb={[3]}
      style={{
        padding: '9px 22px 10px 12px',
        borderColor: '#B4B5B8'
      }}
      css={
        css(
          mq({
            display: ['inline-flex', 'inline-flex', 'none']
          })
        )
      }
    >
      <Call
        css={css`
          path {
            fill: #B4B5B8;
          }
        `}
        style={{
          marginRight: ds.space(2)
        }}
      />
      <span style={{ marginLeft: 4 }}>Ligar</span>
    </Button>
    <a
      href={callTo}
      style={{
        color: '#fff !important'
      }}
    >
      <H6
        variant='highEmphasis'
        selected
        on='onSecondary'
        align='center'
        mb={[1]}
        style={{
          color: '#FFFFFF !important'
        }}
      >
        {number}
      </H6>
    </a>
    <Body2
      variant='highEmphasis'
      color='white'
      align='center'
      mb={[4]}
    >
      {region}
    </Body2>
  </>
)

const FooterLink = ({ children, to = '/' }) => (
  <Body2
    color='white'
    variant='mediumEmphasis'
    as={Link}
    style={{
      cursor: 'pointer',
      display: 'block'
    }}
    to={to}
    css={
      css({
        '&:not(:last-child)': {
          marginBottom: 8
        }
      })
    }
  >
    {children}
  </Body2>
)

const FooterLinkTo = ({
  children,
  linkTo
}) => (
  <>
    <a
      href={linkTo}
      style={{
        color: '#fff !important'
      }}
    >
      <Body2
        color='white'
        variant='mediumEmphasis'
        style={{
          cursor: 'pointer',
          display: 'block',
          marginBottom: 8
        }}
      >
        {children}
      </Body2>
    </a>
  </>
)

const Footer = () => {
  return (
    <Fragment>
      <Row
        flexDirection={['column', 'column', 'row']}
        mx={null}
      >
        <Column
          pt={[40]}
          pb={[50]}
          pl={[16, 16, 24]}
          pr={[16, 16, 12]}
          px={null}
          width={[1]}
          style={{
            backgroundColor: '#4e5159'
          }}
          order={[0, 0, 1, 1]}
        >
          <Subtitle
            align='center'
            color='white'
            variant='mediumEmphasis'
            m={['0 0 16px']}

          >
            #CONNECTEDHDBR
          </Subtitle>
          <H4
            align='center'
            selected
            on='onSecondary'
            variant='mediumEmphasis'
            mb={[3]}
            fontWeight={500}
          >
            Receba nossas<br /> novidades
          </H4>
          <Body2
            align='center'
            mb={[4]}
          >
            Deixe seu e-mail para receber nossa newsletter<br /> de novidades, descontos e lançamentos.
          </Body2>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <EnvioForm
              fields={fields}
              formId={32}
              submitText='Enviar'
            />
          </div>
          <hr
            style={{
              border: 'none',
              borderBottom: `1px solid ${ds.brand('charcoalGrey')}`,
              margin: '50px 0',
            }}
          />
          <H4
            on='onSecondary'
            selected
            variant='mediumEmphasis'
            align='center'
            mb={[9]}
          >
            Siga nossas<br />Redes Sociais
          </H4>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
            css={socialIcons}
          >
            {[
              { icon: faTwitter, link: 'https://twitter.com/hostdimebr' },
              { icon: faFacebookF, link: 'https://www.facebook.com/hostdimebr' },
              { icon: faInstagram, link: 'https://www.instagram.com/hostdimebr/' },
              { icon: faLinkedinIn, link: 'https://www.linkedin.com/company/hostdime-brasil/' },
              { icon: faYoutube, link: 'https://www.youtube.com/user/hostdimebr' }
            ].map((social) => (
              <a
                key={generate()}
                href={social.link}
                style={{
                  width: 50,
                  height: 50,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'rgb(217, 222, 227)',
                  borderRadius: '50%',
                  backgroundColor: ds.brand('charcoalGrey'),
                  fontSize: 20
                }}
              >
                <FontAwesomeIcon icon={social.icon} />
              </a>
            ))}
          </div>
        </Column>
        <Column
          pt={[40]}
          pb={[50]}
          pr={[16, 16, 24]}
          pl={[16, 16, 12]}
          px={null}
          width={[1]}
          style={{
            backgroundColor: '#44474e',
            textAlign: 'center'
          }}
          order={[1, 1, 0, 0]}
        >
          <H4
            align='center'
            selected
            on='onSecondary'
            variant='mediumEmphasis'
            mb={[7]}
            fontWeight={500}
          >
            Estamos<br /> esperando seu<br /> contato
          </H4>
          <TelephoneContact
            number='4007 2085'
            region='Capitais e Regiões Metropolitanas'
            callTo='tel:+4007 2085'
          />
          <TelephoneContact
            number='0800 000 2085'
            region='Demais Regiões'
            callTo='tel:+08000002085'
          />
          <TelephoneContact
            number='(+1) 407.756.1126'
            region='Ligue-nos Internacionalmente'
            callTo='tel:+14077561126'
          />
          <Button
            onClick={() => navigate('/contatos')}
            variant='mediumEmphasis'
            outlined
            schema='whiteTransparent'
            color='white'
            mb={[7]}
            style={{
              padding: '15px 22px 14px 12px',
              borderColor: '#B4B5B8',
              maxWidth: 311,
              width: '100%'
            }}
          >
            enviar um email
          </Button>
          <H6
            color='white'
            variant='mediumEmphasis'
            align='center'
            mb={[1]}
          >
            Vendas / Suporte Financeiro
          </H6>
          <Body2
            color='white'
            variant='mediumEmphasis'
            align='center'
            mb={[3]}
          >
            Segunda / Sexta • 8h - 18h
          </Body2>
          <H6
            color='white'
            variant='mediumEmphasis'
            align='center'
            mb={[1]}
          >
            Suporte
          </H6>
          <Body2
            color='white'
            variant='mediumEmphasis'
            align='center'
          >
            24h por dia
          </Body2>
        </Column>
      </Row>
      <div
        css={
          css(
            mq({
              backgroundColor: '#44474e'
            })
          )
        }
      >
        <hr
          css={
            css(
              mq({
                border: 0,
                borderBottom: `1px solid ${ds.brand('gunMetal')}`,
                marginTop: 0,
                marginBottom: 50,
                width: ['calc(100% - 32px)', 'calc(100% - 48px)', '100%'],
                maxWidth: ['600px', '600px', '100%']
              })
            )
          }
        />
        <Row
          mx={null}
          style={{
            textAlign: 'center'
          }}
          pb={[50]}
        >
          <Column
            width={[1]}
            pl={[16, 16, 24]}
            pr={[16, 16, 24]}
            px={null}
            css={
              css(
                mq({
                  display: 'flex',
                  justifyContent: ['center']
                })
              )
            }
          >
            <Row
              flexWrap={['wrap', 'wrap', 'nowrap']}
              width={['auto', '100%']}
              css={
                css(
                  mq({
                    maxWidth: [360, 'initial']
                  })
                )
              }
            >
              <Column
                width={[0, 0, 1/12]}
                px={[0, 0, 2]}
                order={[0]}
              />
              <Column
                width={[1/2, 1/2, 1/6]}
                mb={[21, 21, 0]}
                order={[1, 1, 3]}
              >
                <H6
                  mb={[4]}
                >
                  PRODUTOS
                </H6>
                <FooterLink
                  to='/servidores-dedicados'
                >
                  Servidores Dedicados
                </FooterLink>
                <FooterLink
                  to='/cloud-server'
                >
                  Server Cloud
                </FooterLink>
                <FooterLink
                  to='/colocation'
                >
                  Colocation
                </FooterLink>
                <FooterLink
                  to='/outros-produtos'
                >
                  Outros Produtos
                </FooterLink>
              </Column>
              <Column
                width={[1/2, 1/2, 1/6]}
                order={[3, 3, 5]}
              >
                <H6
                  mb={[4]}
                >
                  LOCALIZAÇÃO
                </H6>
                <FooterLink
                  to='/contatos'
                >
                  São Paulo - Brasil
                </FooterLink>
                <FooterLink
                  to='/contatos'
                >
                  João Pessoa - Brasil
                </FooterLink>
                <FooterLink
                  to='/contatos'
                >
                  Orlando - USA
                </FooterLink>
              </Column>
              <Column
                width={[0, 0, 1/12]}
                px={[0, 0, 2]}
                order={[4]}
              />
              <Column
                width={[1/2, 1/2, 1/6]}
                mb={[21, 21, 0]}
                order={[5, 5, 1]}
              >
                <H6
                  mb={[4]}
                >
                  SOBRE
                </H6>
                <FooterLink
                  to='/cultura'
                >
                  Cultura
                </FooterLink>
                <FooterLink
                  to='/carreiras'
                >
                  Carreiras
                </FooterLink>
                <FooterLink
                  to='/press-kit'
                >
                  Imprensa
                </FooterLink>
              </Column>
              <Column
                width={[0, 0, 1/12]}
                px={[0, 0, 2]}
                order={[6]}
              />
              <Column
                width={[1/2, 1/2, 1/6]}
                order={[6]}
              >
                <H6
                  mb={[4]}
                >
                  SUPORTE
                </H6>
                <FooterLink
                  to='/suporte'
                >
                  Perguntas Frequentes
                </FooterLink>
                <FooterLinkTo
                  children='Central de Ajuda'
                  linkTo='https://hostdime.com.br/ajuda'
                />
                <FooterLinkTo
                  children='Core Login'
                  linkTo='https://core.hostdime.com.br/support/new'
                />
              </Column>
            </Row>
          </Column>
        </Row>
      </div>
      <Row
        mx={null}
        display={['flex']}
      >
        <Column
          pt={[13]}
          pb={[13]}
          width={[1]}
          style={{
            backgroundColor: '#4e5159',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#b4b5b8',
            fontSize: 12,
            lineHeight: 2,
          }}
        >
          <Div
            display={['flex']}
            flexDirection={['column', 'row']}
          >
            <Div
              textAlign={['center', 'right']}
            >
              © 2019 HostDime Brasil. All rights reserved.
            </Div>
            <Div
              flexDirection={['row']}
              alignItems={['center']}
              justifyContent={['center', 'normal']}
            >
              <div css={dots} /> 
              <Link to='/contrato' style={{ color: '#b4b5b8' }}>
                <u>Contrato</u>
              </Link> 
              <div css={dots} /> 
              <Link to='/politica-de-privacidade' style={{ color: '#b4b5b8' }}>
                <u>Política de Privacidade</u>
              </Link>
            </Div>
          </Div>
        </Column>
      </Row>
    </Fragment>
  )
}

export default Footer
