import React from 'react'
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { mq } from './styles/Layout.styles'
import Atom from './styles/Atom.styles'

/* Default sizes change if you need */
export const StyledCaption = styled(Atom)(props => (
  mq({
    fontWeight: [600],
    lineHeight: [1.36],
    letterSpacing: [0.4]
  })
))

const Caption = ({ children, as, ...props }) => (
  <StyledCaption
    as={as}
    {...props}
  >
    {children}
  </StyledCaption>
)

Caption.propTypes = {
  children: PropTypes.node,
  as: PropTypes.string
}

Caption.defaultProps = {
  fontSize: [11.8],
  as: 'span'
}

export default Caption