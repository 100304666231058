import React from 'react'
import { Link } from 'gatsby'
import { generate } from 'shortid'

import ds from '../../theme'

import { Container } from './../styles/Layout.styles'
import { css } from '@emotion/core'
import History from '@material-ui/icons/History'
import Div from './../Div/Div.styles';

const columnALinks = [
  {
    label: 'Cloud Server HostDime',
    link: '/'
  },
  {
    label: 'Documentação do Cloud Server HostDime',
    link: '/'
  },
  {
    label: 'Newsletter',
    link: '/'
  },
  {
    label: 'Produtos',
    link: '/'
  },
  {
    label: 'Nossa Jornada',
    link: '/'
  },
  {
    label: 'Estrutura DC Nordeste',
    link: '/'
  },
  {
    label: 'Porque a HostDime',
    link: '/'
  }
]

const columnBLinks = [
  {
    label: 'Cloud Server HostDime',
    link: '/'
  },
  {
    label: 'Computação em nuvem',
    link: '/'
  },
  {
    label: 'Cloud Server HostDime: faça uma avaliação gratuita',
    link: '/'
  },
  {
    label: 'Produtos',
    link: '/'
  },
  {
    label: 'Servidores Dedicados',
    link: '/'
  },
  {
    label: 'Nosso Atendimento',
    link: '/'
  }
]

const SearchDrawerItems = ({
  toggleDrawer
}) => {
  return (
    <Container
      style={{
        width: '100%',
        paddingTop: 20,
        paddingRight: 0
      }}
    >
      <Div
        flexDirection={['row']}
        maxWidth={[330]}
      >
        <Div
          width={['55%']}
          flexDirection={['row']}
          mr={[4]}
        >
          <History
            style={{
              marginRight: 24,
              flexShrink: 0,
              color: 'rgba(0, 0, 0, 0.54)',
              width: 24,
              height: 24
            }}
          />
          <div>
            <p
              style={{
                fontSize: 12,
                color: ds.brand('blueGrey'),
                lineHeight: 1,
                marginBottom: 16
              }}
            >
              Páginas Recentes
            </p>
            {columnALinks.map(item => (
              <Link
                key={generate()}
                style={{
                  fontSize: 14,
                  color: ds.brand('blackHighEmphasis'),
                  lineHeight: 1.29,
                  display: 'inline-block',
                  cursor: 'pointer',
                  textDecoration: 'none'
                }}
                css={
                  css`
                    &:not(:last-child) {
                      margin-bottom: 16px;
                    }
                  `
                }
                to={item.link}
                onClick={() => toggleDrawer(false)}
              >
                {item.label}
              </Link>
            ))}
          </div>
        </Div>
        <Div
          width={['45%']}
        >
          <p
            style={{
              fontSize: 12,
              color: ds.brand('blueGrey'),
              lineHeight: 1,
              marginBottom: 16
            }}
          >
            Páginas mais visitadas
          </p>
          {columnBLinks.map(item => (
            <Link
              key={generate()}
              style={{
                fontSize: 14,
                color: ds.brand('blackHighEmphasis'),
                lineHeight: 1.29,
                display: 'inline-block',
                cursor: 'pointer',
                textDecoration: 'none'
              }}
              css={
                css`
                  &:not(:last-child) {
                    margin-bottom: 16px;
                  }
                `
              }
              to={item.link}
              onClick={() => toggleDrawer(false)}
            >
              {item.label}
            </Link>
          ))}
        </Div>
      </Div>
    </Container>
  )
}

export default SearchDrawerItems