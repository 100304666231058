import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { fromEvent, asyncScheduler } from 'rxjs'
import { throttleTime, tap } from 'rxjs/operators'
import { navigate }  from 'gatsby'

import { mq, Container } from './styles/Layout.styles'
import Button from './Button'
import H6 from './H6'
import Modal from './Modal'
import WannaBuyModal from './WannaBuyModal'

const initialModals = {
  support: false,
  moreInfo: false
}

const SubNav = (
  props
) => {
  const [visible, setVisible] = useState(true)
  const [lastOffset, setLastOffset] = useState(0)
  const [modals, setModals] = useState(initialModals)

  const setModalVisibility = modal => visible => {
    setModals({
      ...modals,
      [modal]: visible
    })
  }

  const scrollHandler = () => {
    const currentTopOffset = window.pageYOffset

    setVisible(currentTopOffset < lastOffset || currentTopOffset <= 10)

    setLastOffset(window.pageYOffset)
  }

  useEffect(() => {
    const listener = fromEvent(document, 'scroll')
      .pipe(
        throttleTime(20, asyncScheduler, { leading: false, trailing: true }),
        tap(scrollHandler)
      )
      .subscribe();
    
    return () => listener.unsubscribe()
  })

  return (
    <div
      css={css(mq({
        boxSizing: 'border-box'
      }))}
      style={{
        borderBottom: '1px solid #ECEEF1',
        height: '76.5px',
        position: 'fixed',
        top: '72px',
        left: 0,
        transform: `translateY(${visible ? 0 : '-96px'})`,
        transition: 'transform 0.2s ease',
        zIndex: 1100,
        backgroundColor: 'white',
        width: '100%'
      }}
    >
      <Container
        style={{
            maxWidth: '100%'
          }}
      >
        <div
          css={css(mq({
            display: 'flex',
            alignItems: 'center',
            justifyContent: ['center', 'flex-end'],
            height: '100%'
          }))}
        >
          <Button
            outlined
            schema='lightPeriwinkle'
            align='center'
            style={{
              color: '#737474',
              fontWeight: 600,
              letterSpacing: 'normal',
              lineHeight: 1.43,
              padding: '12px 16px 12px',
              width: 156,
              textTransform: 'capitalize',
              border: 'solid 1px rgba(217, 222, 227, 0.5)',
              outline: 'none',
              marginRight: 16
            }}
            onClick={() => setModalVisibility('support')(true)}
          >
            Quero Suporte
          </Button>
          <Button
            align='center'
            style={{
              fontWeight: 600,
              letterSpacing: 'normal',
              lineHeight: 1.43,
              padding: '12px 16px 12px',
              width: 156,
              textTransform: 'capitalize',
              color: '#f9fafa',
              outline: 'none'
            }}
            onClick={() => setModalVisibility('moreInfo')(true)}
          >
            Quero Comprar
          </Button>
        </div>
      </Container>
      <Modal
        open={modals.support}
        onClose={() => setModalVisibility('support')(false)}
        style={{
          zIndex: 2000
        }}
      >
        <H6
          selected
          on='onSurface'
          variant='highEmphasis'
          align='center'
          mb={[7]}
        >
          Precisa de suporte,<br />tirar dúvidas, tutoriais<br />ou falar com algum especialista?
        </H6>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <a
            href='https://core.hostdime.com.br/'
          >
            <Button
              schema='secondary'
              color='white'
              variant='highEmphasis'
              width={['100%']}
              maxWidth={['210px']}
              style={{
                display: 'flex',
                border: 'none',
                margin: '0 auto 8px',
                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
              }}
            >
              abrir um chamado
            </Button>
          </a>
          <Button
            onClick={() => navigate('/suporte')}
            schema='white'
            outlined
            selected
            variant='base'
            on='onSurface'
            width={['100%']}
            maxWidth={['210px']}
            style={{
              display: 'flex',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              margin: '0 auto'
            }}
          >
            central de ajuda
          </Button>
        </div>
      </Modal>
      <WannaBuyModal
        visible={modals.moreInfo}
        onClose={() => setModalVisibility('moreInfo')(false)}
      />
    </div>
  )
}

export default SubNav