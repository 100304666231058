import styled from '@emotion/styled'
import { mq } from './../styles/Layout.styles'

const Div = styled('div')(props => (
  mq({
    margin: props.m,
    marginTop: props.mt.map(m => props.theme.ds.space(m)),
    marginBottom: props.mb.map(m => props.theme.ds.space(m)),
    marginLeft: props.mx || props.ml.map(m => props.theme.ds.space(m)),
    marginRight: props.mx || props.mr.map(m => props.theme.ds.space(m)),
    padding: props.p,
    paddingLeft: props.px || props.pl.map(p => props.theme.ds.space(p)),
    paddingRight: props.px || props.pr.map(p => props.theme.ds.space(p)),
    paddingTop: props.pt.map(p => props.theme.ds.space(p)),
    paddingBottom: props.pb.map(p => props.theme.ds.space(p)),
    width: props.width.map(width => {
      return typeof width === 'number'
        ? `${100 * width}%`
        : width
    }),
    height: props.height.map(height => {
      return typeof width === 'number'
        ? `${100 * height}%`
        : height
    }),
    order: props.order,
    display: props.display,
    justifyContent: props.justifyContent,
    alignItems: props.alignItems,
    flexDirection: props.flexDirection,
    flexWrap: props.flexWrap,
    boxSizing: 'border-box',
    maxWidth: props.maxWidth,
    textAlign: props.textAlign
  })
))

Div.defaultProps = {
  mt: [],
  mb: [],
  ml: [],
  mr: [],
  pt: [],
  pb: [],
  pl: [],
  pr: [],
  width: [1],
  height: ['auto'],
  flexDirection: 'column',
  display: 'flex'
}

export default Div
