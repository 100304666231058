import { css } from '@emotion/core'
import reset from 'emotion-reset'

import ds from '../../theme'

export const globalStyles = css`
  ${reset}
  #___gatsby {
    height: 100%;
    & > div {
      height: 100%;
    }
  }
  html, body {
    height: 100%;
    font-family: 'Montserrat',sans-serif !important;
  }
  body {
    font-family: ${ds.get('type.fontFamily.sans')};
    line-height: ${ds.get('type.lineHeight.base')};
    padding-right: 0!important; /* Fix for material-ui Drawer behavior */
    overflow: auto!important;
  }
  a {
    text-decoration: none;
  }
  input,
  textarea {
    font-family: inherit;
    box-sizing: border-box;
  }
  button {
    border: none;
  }
  .MuiTypography-body1 {
    font-size: 14px;
    font-family: 'Montserrat',sans-serif !important;
    font-weight: 500;
    line-height: 1.71;
    letter-spacing: normal;
    color: #737474;
  }
  .MuiTypography-body1:hover {
    color: rgba(0,0,0,0.87);
  }
  .MuiListItem-button:hover {
    text-decoration: none;
    background-color: #fff;
  }
  .PrivateNotchedOutline-root {
    border-radius: 22.5px;
    height: 56px;
    border: solid 1px rgba(0, 0, 0, 0.32);
  }
  .MuiFormControl-root {
    margin-bottom: 10px;
  }
  .MuiFormLabel-root {
    padding: 0;
    font-family: 'Montserrat',sans-serif !important;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.15;
  }
  .MuiInputBase-root {
    font-family: 'Montserrat',sans-serif !important;
    min-height: 56px;
    width: 328px;
  }
  .PrivateNotchedOutline-root-101 {
    border-radius: 50px;
  }
  .MuiInputBase-input {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
    font-size: 14px;
  }
  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 0px 0px 14px rgb(255, 245, 239);
  }
  .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 14px rgb(255, 245, 239);
  }
`
