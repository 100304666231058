
import { mq } from './Layout.styles'
import styled from '@emotion/styled';
import Atom from './Atom.styles'

/* Default sizes change if you need */
export const StyledSubtitle = styled(Atom)(props => (
  mq({
    fontWeight: [600],
    lineHeight: [1.53],
    letterSpacing: [0.15]
  })
))