import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledH6
} from './styles/H6.styles'

const H6 = ({ children, ...props }) => (
  <StyledH6
    as='h6'
    {...props}
  >
    {children}
  </StyledH6>
)

H6.propTypes = {
  children: PropTypes.node.isRequired
}

H6.defaultProps = {
  fontSize: [19.6]
}

export default H6
