import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledP
} from './styles/Paragraph.styles'

const Paragraph = ({ children, fontSize, ...props }) => (
  <StyledP
    as='p'
    fontSize={fontSize}
    {...props}
  >
    {children}
  </StyledP>
)

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  fontSize: PropTypes.array
}

Paragraph.defaultProps = {
  fontSize: [14]
}

export default Paragraph
