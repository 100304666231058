import React, { useState } from 'react'
import Check from '@material-ui/icons/Check'
// import TextMaskInput from 'react-text-mask'

import Button from './Button'
import H6 from './H6';
// import Body2 from './Body2'
import Modal from './Modal'
import LigamosParaVoceForm from '../components/LigamosParaVoceForm'
import EnvieSuaMensagem from '../components/EnvieSuaMensagem'

const callTo = [
  {
    label: 'Nome',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }
]

const sendMensagem = [
  {
    label: 'Nome',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: 'Mensagem',
    name: 'your-message',
    type: 'text',
    required: true
  }
]



// const Input = ({ field, form, style, ...rest }) => (
//   <Body2
//     as='input'
//     color='black'
//     variant='mediumEmphasis'
//     style={{
//       backgroundColor: '#eceded',
//       borderRadius: 4,
//       border: 'none',
//       padding: '16px',
//       width: '100%'
//     }}
//     {...field}
//     {...rest}
//   />
// )

// const MaskedInput = ({ field, form, style, ...rest }) => (
//   <Body2
//     as={TextMaskInput}
//     color='black'
//     variant='mediumEmphasis'
//     style={{
//       backgroundColor: '#eceded',
//       borderRadius: 4,
//       border: 'none',
//       padding: '16px',
//       width: '100%'
//     }}
//     {...field}
//     {...rest}
//   />
// )

const SentMessage = ({
  onClick
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 30
        }}
      >
        <div
          style={{
            width: 50,
            height: 50,
            backgroundColor: '#7ed321',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Check
            style={{
              color: 'white',
              flexShrink: 0,
              fontSize: 36
            }}
          />
        </div>
      </div>
      <H6
        selected
        on='onSurface'
        variant='highEmphasis'
        align='center'
        mb={[5]}
      >
        Agradecemos seu<br />
        contato! Retornaremos<br />
        em breve.
      </H6>
      <Button
        schema='white'
        outlined
        selected
        variant='base'
        on='onSurface'
        width={['100%']}
        maxWidth={['210px']}
        style={{
          display: 'flex',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          margin: '0 auto'
        }}
        onClick={onClick}
      >
        Fechar
      </Button>
    </>
  )
}

const CallMessage = ({
  onClick
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 30
        }}
      >
        <div
          style={{
            width: 50,
            height: 50,
            backgroundColor: '#7ed321',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Check
            style={{
              color: 'white',
              flexShrink: 0,
              fontSize: 36
            }}
          />
        </div>
      </div>
      <H6
        selected
        on='onSurface'
        variant='highEmphasis'
        align='center'
        mb={[5]}
      >
        Ligaremos para<br />
        você em instantes.<br />
        Obrigado!
      </H6>
      <Button
        schema='white'
        outlined
        selected
        variant='base'
        on='onSurface'
        width={['100%']}
        maxWidth={['210px']}
        style={{
          display: 'flex',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          margin: '0 auto'
        }}
        onClick={onClick}
      >
        Fechar
      </Button>
    </>
  )
}

const WannaBuyModal = ({
  visible,
  onClose
}) => {
  const [status, setStatus] = useState('default')
  const onCloseModal = () => {
    setStatus('default')
    onClose()
  }
  const statuses = {
    default: (
      <>
        <H6
          selected
          on='onSurface'
          variant='highEmphasis'
          align='center'
          mb={[4]}
        >
          Precisa de mais<br />informações sobre nossos<br />produtos ou gostaria de<br />comprar? Fale agora<br />conosco.
        </H6>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            schema='secondary'
            color='white'
            variant='highEmphasis'
            width={['100%']}
            maxWidth={['210px']}
            style={{
              display: 'flex',
              border: 'none',
              margin: '0 auto 8px',
              boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
            }}
            onClick={() => {
              setStatus('callForm')
            }}
          >
            ligamos pra você
          </Button>
          <Button
            schema='white'
            outlined
            selected
            variant='base'
            on='onSurface'
            width={['100%']}
            maxWidth={['210px']}
            style={{
              display: 'flex',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              margin: '0 auto'
            }}
            onClick={() => {
              setStatus('messageForm')
            }}
          >
            contato
          </Button>
        </div>
      </>
    ),
    sent: (
      <SentMessage
        onClick={onCloseModal}
      />
    ),
    call: (
      <CallMessage
        onClick={onCloseModal}
      />
    ),
    callForm: (
      <>
        <H6
          selected
          on='onSurface'
          variant='highEmphasis'
          align='center'
          mb={[4]}
        >
          Ligamos pra Você
        </H6>
        <LigamosParaVoceForm
          fields={callTo}
          formId={35}
          submitText='Enviar'
        />
        {/* <Input
          placeholder='Nome'
          mb={[3]}
        />
        <MaskedInput
          placeholder='Telefone'
          mb={[4]}
          mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            schema='secondary'
            color='white'
            variant='highEmphasis'
            width={['100%']}
            maxWidth={['210px']}
            style={{
              display: 'flex',
              border: 'none',
              margin: '0 auto',
              boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
            }}
            onClick={() => setStatus('call')}
          >
            enviar
          </Button>
        </div> */}
      </>
    ),
    messageForm: (
      <>
        <H6
          selected
          on='onSurface'
          variant='highEmphasis'
          align='center'
          mb={[4]}
        >
          Envie sua Mensagem
        </H6>
        <EnvieSuaMensagem
          fields={sendMensagem}
          formId={37}
          submitText='Enviar'
        />
        {/* <Input
          placeholder='Nome'
          mb={[3]}
        />
        <Input
          placeholder='Email'
          mb={[3]}
        />
        <MaskedInput
          placeholder='Telefone'
          mb={[3]}
          mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        />
        <Input
          placeholder='Mensagem'
          mb={[4]}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            schema='secondary'
            color='white'
            variant='highEmphasis'
            width={['100%']}
            maxWidth={['210px']}
            style={{
              display: 'flex',
              border: 'none',
              margin: '0 auto',
              boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
            }}
            onClick={() => setStatus('sent')}
          >
            enviar
          </Button>
        </div> */}
      </>
    )
  }

  return (
    <Modal
      open={visible}
      hasArrowBack={['callForm', 'messageForm'].includes(status)}
      hideIcons={status === 'sent'}
      onClose={onCloseModal}
      onBack={() => setStatus('default')}
      style={{
        zIndex: 2000,
      }}
    >
      {statuses[status]}
    </Modal>
  )
}

export default WannaBuyModal
