import React from 'react'

import SearchCancelSVG from '../images/icons/icon-navigation-cancel-24-px.svg'

const SearchCancel = ({ style, ...rest }) => {
  return (
    <SearchCancelSVG
      style={{
        width: 24,
        height: 24,
        background: `url(${SearchCancelSVG}) no-repeat center center`,
        cursor: 'pointer',
        flexShrink: 0,
        ...style
      }}
      {...rest}
    />
  )
}

export default SearchCancel
