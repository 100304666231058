import React from 'react'
import PropTypes from 'prop-types'

import AvatarSVG from '@material-ui/icons/AccountCircle'

const Avatar = ({
  size,
  style,
  ...rest
}) => (
  <AvatarSVG
    style={{
      width: 24,
      height: 24,
      color: 'rgba(0, 0, 0, 0.54)',
      cursor: 'pointer',
      borderRadius: '50%',
      flexShrink: 0,
      ...style
    }}
    {...rest}
  />
)

Avatar.propTypes = {
  size: PropTypes.string,
  style: PropTypes.object
}

export default Avatar