import styled from '@emotion/styled'
import { css } from '@emotion/core'
import facepaint from 'facepaint'
import { pxTo } from 'design-system-utils'

import ds from '../../theme'

export const mq = facepaint([
  `@media(min-width: ${ds.get('breakpoints.tablet')})`,
  `@media(min-width: ${ds.get('breakpoints.tabletPlus')})`,
  `@media(min-width: ${ds.get('breakpoints.desktop')})`
])

export const toRem = px => pxTo(px, ds.get('baseFontSize'), 'rem')

export const Container = styled.div(
  mq({
    margin: '0 auto',
    maxWidth: ['360px', '600px', '1080px', '1284px'],
    padding: ['0 16px', '0 16px', '0 24px', '0 24px'],
    boxSizing: 'border-box',
    height: '100%',
    position: 'relative'
  })
)

export const head = css`
  background-color: ${ds.brand('primary')};
`

const footerBp = css(
  mq({
    fontSize: [10, 14],
    padding: ['21px 0', '34px 0 28px']
  })
)

export const Footer = styled.footer`
  background-color: #00b0cc;
  text-align: center;
  font-weight: 500;
  color: ${ds.brand('text')};
  ${footerBp}
`
