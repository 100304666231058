import React from 'react'

import SearchSVG from '@material-ui/icons/Search'

const Search = ({ style, ...rest }) => {
  return (
    <SearchSVG
      style={{
        width: 24,
        height: 24,
        color: 'rgba(0, 0, 0, 0.54)',
        cursor: 'pointer',
        flexShrink: 0,
        ...style
      }}
      {...rest}
    />
  )
}

export default Search
