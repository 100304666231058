import React from 'react'
import PropTypes from 'prop-types';

import {
  StyledSubtitle
} from './styles/SubTitle.styles'

const Subtitle = ({ children, as, ...props }) => (
  <StyledSubtitle
    as={as}
    {...props}
  >
    {children}
  </StyledSubtitle>
)

Subtitle.propTypes = {
  children: PropTypes.node,
  as: PropTypes.string
}

Subtitle.defaultProps = {
  fontSize: [15.7],
  as: 'p'
}

export default Subtitle