import styled from '@emotion/styled'
import { toRem, mq } from './Layout.styles'

import ds from '../../theme'

const textColors = {
  primary: {
    base: ds.brand('iceBlue'),
    onSurface: '#737474'
  },
  secondary: {
    base: ds.brand('secondary'),
    onSurface: '#e64b00'
  },
  black: {
    base: '#000000',
    mediumEmphasis: ds.brand('blackMediumEmphasis'),
    highEmphasis: ds.brand('blackHighEmphasis'),
    disabled: ds.brand('disabled')
  },
  white: {
    base: '#FFFFFF',
    disabled: ds.brand('disabledWhite'),
    mediumEmphasis: ds.brand('whiteMediumEmphasis'),
    highEmphasis: ds.brand('whiteHighEmphasis'),
  }
}

const selectedColors = {
  onPrimary: {
    mediumEmphasis: ds.brand('gunMetal'),
    highEmphasis: ds.brand('gunMetal')
  },
  onSecondary: {
    disabled: ds.brand('disabled'),
    highEmphasis: ds.brand('whiteHighEmphasis'),
    mediumEmphasis: '#FFFFFF'
  },
  onSurface: {
    disabled: ds.brand('disabled'),
    highEmphasis: ds.brand('blackHighEmphasis'),
    mediumEmphasis: ds.brand('blackMediumEmphasis'),
    base: '#737474'
  }
}

const Atom = styled('div')(props => (
  mq({
    color: !props.disabled
      ? props.selected
        ? selectedColors[props.on][props.variant]
        : textColors[props.color][props.variant]
      : 'rgba(0, 0, 0, 0.38)',
    fontSize: props.fontSize.map(toRem),
    textAlign: props.align,
    margin: props.m,
    marginTop: props.mt.map(m => props.theme.ds.space(m)),
    marginBottom: props.mb.map(m => props.theme.ds.space(m)),
    marginLeft: props.ml.map(m => props.theme.ds.space(m)),
    marginRight: props.mr.map(m => props.theme.ds.space(m)),
    width: props.width,
    textDecoration: 'none',
    maxWidth: props.maxWidth,
    order: props.order,
    height: props.height,
    display: props.display
  })
))

Atom.defaultProps = {
  mt: [],
  mb: [],
  ml: [],
  mr: [],
  align: undefined,
  fontSize: [],
  selected: false,
  on: 'onPrimary',
  color: 'primary',
  variant: 'base',
}

export default Atom