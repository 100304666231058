import styled from '@emotion/styled'

import ds from '../../theme'
import Atom from './../styles/Atom.styles'
import { mq } from './../styles/Layout.styles'

const buttonSchemas = {
  primary: {
    backgroundColor: ds.brand('primary'),
    border: `1px solid ${ds.brand('primary')}`
  },
  secondary: {
    backgroundColor: ds.brand('secondary'),
    border: `1px solid ${ds.brand('secondary')}`
  },
  white: {
    backgroundColor: '#FFFFFF',
    border: `1px solid #FFFFFF`
  }
}

const outlinedSchemas = {
  primary: {
    backgroundColor: 'transparent',
    border: `1px solid ${ds.brand('primary')}`
  },
  secondary: {
    backgroundColor: 'transparent',
    border: `1px solid ${ds.brand('secondary')}`
  },
  white: {
    backgroundColor: 'transparent',
    border: `1px solid #FFFFFF`
  },
  lightPeriwinkle: {
    backgroundColor: 'transparent',
    border: `1px solid ${ds.brand('lightPeriwinkle')}`
  },
  whiteTransparent: {
    backgroundColor: 'transparent',
    border: `1px solid rgba(255, 255, 255, 0.6)`
  }
}

const buttonSizes = {
  small: '10px 16px',
  medium: '15px 16px 14px'
}

export const StyledButton = styled(Atom)(props => (
  mq({
    border: 'none',
    outline: 'none',
    padding: buttonSizes[props.size],
    fontWeight: 600,
    borderRadius: '22.5px',
    cursor: 'pointer',
    lineHeight: 1.16,
    letterSpacing: '1.25px',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: props.theme.ds.get('type.fontFamily.sans'),
    ...props.outlined ? outlinedSchemas[props.schema] : buttonSchemas[props.schema]
  })
))