import React, { useState } from 'react'

import Body2 from '../Body2'
import ds from '../../theme'
import Div from './../Div';
import Search from '../Search'

const ActiveSearch = ({
  visible,
  onChange
}) => {
  const [query, setQuery] = useState('')

  return (
    <Div
      style={{
        flexGrow: 1,
        position: 'relative',
        zIndex: 999
      }}
      ml={[0, 0, 4]}
      mr={[3]}
    >
      <Search
        style={{
          position: 'absolute',
          left: 11,
          top: 8,
          cursor: 'default'
        }}
      />
      <form>
        <Body2
          color='black'
          variant='mediumEmphasis'
          as='input'
          placeholder='Pesquisa'
          defaultValue={query}
          onChange={e => {
            setQuery(e.target.value)
            if (onChange) {
              onChange(e.target.value)
            }
          }}
          type='text'
          style={{
            border: 0,
            padding: '11px 40px',
            borderRadius: 6,
            backgroundColor: ds.brand('iceBlue'),
            width: '100%',
            position: 'relative',
            zIndex: 2000,
            lineHeight: 1
          }}
        />
      </form>
    </Div>
  )
}

export default ActiveSearch