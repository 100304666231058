import { mq } from './Layout.styles'
import styled from '@emotion/styled'

import Atom from './Atom.styles'

/* Default sizes change if you need */
export const StyledBody = styled(Atom)(props => (
  mq({
    lineHeight: [1.71]
  })
))
