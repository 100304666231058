import React, { useState } from 'react'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Select from 'react-select'

const useStyles = ({ opaque, selectStyle }) => ({
  control: (styles, { menuIsOpen }) => ({
    ...styles,
    backgroundColor: opaque ? '#f3f5f5' : 'transparent',
    padding: !menuIsOpen && '1px 1px 0 1px',
    minHeight: 56,
    borderRadius: 30,
    fontSize: 15.7,
    fontFamily: 'inherit',
    borderColor: menuIsOpen ? '#4e5159' : opaque ? '#f3f5f5' : 'rgba(0, 0, 0, 0.32)',
    transition: 'border-color 0.2s',
    lineHeight: '24px',
    fontWeight: '600',
    borderBottom: menuIsOpen && 'none!important',
    borderBottomLeftRadius: menuIsOpen && 0,
    borderBottomRightRadius: menuIsOpen && 0,
    boxShadow: menuIsOpen ? 'none' : 'none',
    borderWidth: menuIsOpen ? '2px' : '1px',
    '&:hover': {
      borderColor: '#4e5159',
    },
    ...selectStyle
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      padding: '16px 28px',
      fontSize: 15.7,
      lineHeight: '24px',
      fontWeight: 600,
      position: 'relative',
      zIndex: 2,
      '&:hover': {
        backgroundColor: opaque ? '#FFF' : '#f3f5f5'
      },
      backgroundColor: opaque
      ? isDisabled
        ? null
        : isSelected
          ? '#FFF'
          : null
      : isDisabled
        ? null
        : isSelected
          ? '#f3f5f5'
          : null,
      color: isDisabled
        ? '#CCC'
        : 'rgba(0, 0, 0, 0.6)',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? opaque ? '#FFF' : '#f3f5f5' : 'rgba(0, 0, 0, 0.3)'),
      },
      ':not(:last-child)': {
        '&:after': {
          content: '""',
          borderBottom: 'solid 1px rgba(217, 222, 227, 0.5)',
          position: 'absolute',
          bottom: -1,
          marginBottom: 1,
          width: 'calc(100% - 56px)',
          left: 28
        }
      }
    };
  },
  input: styles => ({ ...styles }),
  valueContainer: styles => ({ ...styles, paddingLeft: 26 }),
  placeholder: styles => ({ ...styles }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: styles => ({ ...styles, marginRight: 14, color: '#4e5159' }),
  menu: (styles) => ({
    ...styles,
    margin: 0,
    borderRadius: 0,
    borderTop: 'none',
    borderLeft: '2px solid #4e5159',
    borderRight: '2px solid #4e5159',
    borderBottom: '2px solid #4e5159',
    boxShadow: 'none',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    backgroundColor: opaque ? '#f3f5f5' : '#FFF',
    zIndex: 10,
    overflow: 'hidden'
  }),
  menuList: (styles) => ({ ...styles, border: 0, boxShadow: 'none', padding: '0 0 14px', position: 'relative', zIndex: 5 }),
  singleValue: (styles, { menuIsOpen }) => ({ ...styles, color: 'rgba(0, 0, 0, 0.6)' })
});

const Dropdown = ({
  name,
  label,
  options,
  opaque,
  style,
  onChange,
  value,
  selectStyle,
  isClearable,
  isSearchable
}) => {
  const [internalValue, setValue] = useState()
  const handleChange = newValue => {
    setValue(newValue)
  }

  return (
    <div
      style={{
        width: '100%',
        ...style
      }}
    >
      <Select
        placeholder={label}
        IconComponent={KeyboardArrowDown}
        value={value || internalValue}
        onChange={onChange || handleChange}
        options={options}
        styles={useStyles({ opaque, selectStyle })}
        isClearable={isClearable}
        isSearchable={isSearchable}
      />
    </div>
  )
}

Dropdown.defaultProps = {
  isClearable: true,
  isSearchable: false
}

export default Dropdown
