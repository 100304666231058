import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'

import Header from './Header'
import { globalStyles } from './styles/global.styles'
import ds from '../theme'

import Footer from '../components/Footer'
import SubNav from './SubNav';

const theme = {
  space: ds.get('spacing.gutters'),
  breakpoints: Object.keys(
      ds.get('breakpoints')
    ).map(
      key => ds.get('breakpoints')[key]
    ),
  ds
}

const Layout = ({
  children
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <ThemeProvider theme={theme}>
          <Global styles={globalStyles} />
          <Header
            siteTitle={data.site.siteMetadata.title}
          />
          <SubNav />     
          <div
            style={{
              paddingTop: 148
            }}
          >
            <main>{children}</main>
            <Footer />
          </div>
        </ThemeProvider>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
