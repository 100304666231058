import React from 'react'
import { Field, withFormik } from 'formik'
import axios from 'axios'

import NewsletterField from './../components/NewsletterField'
import Button from './../components/Button/Button'
import MuiSelect from '../components/Dropdown'

const FormikField = ({
  field,
  form,
  ...rest
}) => {
  return (
    <NewsletterField
      {...field}
      {...rest}
    />
  )
}

const FormikSelect = ({
  field,
  form,
  options,
  label,
  ...rest
}) => {
  return (
    <MuiSelect
      options={options}
      label={label}
      value={options.find(option => option.value === field.value)}
      onChange={(value) => {
        form.setFieldValue(field.name, value.value)
      }}
      isClearable={false}
      {...rest}
    />
  )
}

const validate = (value) => {
  let error
  if (!value) {
    error = 'Campo Obrigatório'
  }
  return error
}

const EnvioForm = ({
  fields,
  values,
  isSubmitting,
  isValid,
  errors,
  submitForm,
  submitText,
  status
}) => {
  console.log(values)
  console.log(errors)
  return (
    <>
      {fields.map((field, i) => field.type !== 'select'
      ? (
        <Field
          key={`contact-field-${i}`}
          variant='outlined'
          label={field.label}
          name={field.name}
          placeholder={field.label}
          component={FormikField}
          validate={field.required && validate}
          multiline={field.multiline}
          rows={field.rows}
          mask={field.mask}
          style={{
            maxWidth: 328,
            marginBottom: 16,
            color: '#fff',
            textAlign: 'center'
          }}
        />
      )
      : (
        <Field
          key={`contact-field-${i}`}
          label={field.label}
          name={field.name}
          placeholder={field.label}
          component={FormikSelect}
          options={field.options}
          validate={field.required && validate}
          multiline={field.multiline}
          rows={field.rows}
          mask={field.mask}
          style={{
            width: 328,
            marginBottom: 10,
            textAlign: 'left'
          }}
        />
      ))}
      <Button
        schema='white'
        color='white'
        on='onPrimary'
        variant='highEmphasis'
        width={['104px']}
        type='submit'
        size='small'
        disabled={isSubmitting || !isValid}
        style={{
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
          color: '#4e5159',
          backgroundColor: '#f9fafa'
        }}
        onClick={() => submitForm()}
      >
        {submitText}
      </Button>
      {status === 'submitted' && (
        <p
          style={{
            marginTop: 16,
            color: '#7ed321',
            fontSize: 12,
            width: 328,
            textAlign: 'center'
          }}
        >
          Formulário enviado com sucesso!
        </p>
      )}
      {status === 'error' && (
        <p
          style={{
            marginTop: 16,
            color: '#b00020',
            fontSize: 12,
            width: 328,
            textAlign: 'center'
          }}
        >
          Desculpe-nos, não foi possível enviar o formulário no momento.
          <br />
          Tente novamente mais tarde.
        </p>
      )}
    </>
  )
}

EnvioForm.defaultProps = {
  submitText: 'Enviar'
}

export default withFormik({
  mapPropsToValues: () => ({}),
  handleSubmit: async (values, { setSubmitting, setStatus, props }) => {
    setStatus('unsubmitted')
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.set(key, values[key])
      })
      setSubmitting(true)
      const response = await axios
        .post(`https://hostdime.com.br/wp/wp-json/contact-form-7/v1/contact-forms/${props.formId}/feedback`, formData)

      console.log(response.data)
      if (response.data.status === 'validation_failed') {
        throw new Error('Formulário inválido.')
      }

      setStatus('submitted')
      setSubmitting(false)
    } catch (e) {
      console.error(e)
      setStatus('error')
      setSubmitting(false)
    }
  }
})(EnvioForm)