import React from 'react'
import { Flex } from '@rebass/grid/emotion'
import { css as cssStyle } from '@emotion/core'
import { mq } from './styles/Layout.styles';

const Row = ({
  mx,
  css,
  maxWidth,
  ...rest
}) => (
  <Flex
    {...rest}
    mx={mx}
    css={[
      css,
      cssStyle(
        mq({
          maxWidth
        })
      )
    ]}
  />
)

Row.defaultProps = {
  mx: [-1, -1, -2, -2]
}

export default Row
