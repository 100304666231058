import React from 'react'
import styled from '@emotion/styled'
import { Box } from '@rebass/grid/emotion'

import { mq } from './styles/Layout.styles'

const ExtBox = styled(Box)(props =>
  mq({
    display: props.display,
    alignItems: props.alignItems,
    justifyContent: props.justifyContent
  })
)

ExtBox.displayName = 'Column'

const Column = ({ px, ...rest }) => <ExtBox {...rest} px={px} />

Column.defaultProps = {
  px: [1, 1, 2, 2]
}

export default Column
