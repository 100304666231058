import DesignSystem from 'design-system-utils'

// your design tokens object goes here, see below for further details
const designTokens = {
  type: {
    baseFontSize: '16px',
    fontFamily: {
      system:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans"',
      sans: 'Montserrat, sans-serif',
      serif: 'Georgia, "Times New Roman", Times, serif',
      mono: 'Menlo, Monaco, "Courier New", monospace'
    },
    lineHeight: {
      base: 1.2
    }
  },
  spacing: {
    scale: [0, 4, 8, 16, 24, 32, 40, 48, 56, 64],
    gutters: [0, 8, 12]
  },
  breakpoints: {
    tablet: '600px',
    tabletPlus: '840px',
    desktop: '1033px'
  },
  colors: {
    // Used with `ds.brand('colorName')`
    brand: {
      primary: '#F9FAFA',
      secondary: '#FF5800',
      text: '#000000',
      background: '#FFFFFF',
      foreground: '#5cd3e6',
      iceBlue: '#f3f5f5',
      iceBlueBright: '#f9fafa',
      gunMetal: '#4e5159',
      charcoalGrey: '#44474e',
      violet: '#9013fe',
      lightPeriwinkle: '#d9dee3',
      brightCyan: '#50e3dc',
      veryLightPink: '#fff5ef',
      paleGrey: '#eef1f5',
      blueGrey: '#878694',
      blackHighEmphasis: 'rgba(0, 0, 0, 0.87)',
      blackMediumEmphasis: 'rgba(0, 0, 0, 0.6)',
      whiteHighEmphasis: '#FFFFFF',
      whiteMediumEmphasis: 'rgba(255, 255, 255, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      disabledWhite: 'rgba(255, 255, 255, 0.38)',
      surface: 'rgba(98, 2, 238, 0)',
      surfaceText: '#737474'
    }
  }
}

export default new DesignSystem(designTokens)
