import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Collapse from '@material-ui/core/Collapse'
import { css } from '@emotion/core'
import { mq } from './styles/Layout.styles';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(9)
  }
}))

export const listItem = css(
  mq({
    paddingLeft: [20, 20, 28]
  })
)

const NestedListItem = ({
  nested,
  children,
  onClick
}) => {
  const classes = useStyles()
  const [opened, setOpened] = useState(false)

  return (
    <>
      <ListItem
        button
        onClick={() => setOpened(!opened)}
        css={listItem}
      >
        {children}
      </ListItem>
      <Collapse in={opened} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {nested.map(nestedItem => (
            <ListItem
              key={nestedItem.label}
              button
              className={classes.nested}
              onClick={() => onClick(nestedItem.link)}
              style={{
                paddingBottom: 0
              }}
            >
              <span
                style={{
                  width: '100%',
                  borderBottom: '1px solid rgba(217, 222, 227, 0.5)'
                }}
              >
                {nestedItem.icon && (
                  <ListItemIcon>
                    {nestedItem.icon}
                  </ListItemIcon>
                )}
                <ListItemText primary={nestedItem.label} />
              </span>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
}

export default NestedListItem