import { css } from '@emotion/core'
import styled from '@emotion/styled'

import ds from '../../theme'

import { mq, toRem } from './Layout.styles'

const menuBreakpoints = css(
  mq({
    marginTop: [ds.spacing(3), ds.spacing(3), 0],
    display: ['none', 'none', 'flex'],
    marginRight: 20
  })
)

export const menu = css`
  ${menuBreakpoints}
`

const itemSizes = css(
  mq({
    fontSize: [12, 14, 14, 14].map(toRem),
    padding: ['0 6.5px', '0 10px', '0 12px']
  })
)

export const MenuItem = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  transition: color 0.2s ease;
  color: rgba(0, 0, 0, 0.6);
  &:hover {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
  }
  & > a {
    ${itemSizes}
    color: inherit;
    text-decoration: none;
    font-family: ${ds.get('type.fontFamily.sans')};
    white-space: nowrap;
    font-weight: 500;
    line-height: 1.71;
    letter-spacing: normal;
  }
`

export const ContentBridge = styled.div`
  position: absolute;
  opacity: 0;
  width: 100%;
  top: 20px;
  height: calc(100% + 10px);
  transition: opacity 0.2s ease;
  pointer-events: none;
  ${MenuItem}:hover & {
    opacity: 1;
    pointer-events: initial;
  }
`

export const DropdownContent = styled.div`
  top: calc(100% - 20px);
  position: absolute;
  width: 220px;
  transform: translate(-50%, 0);
  left: 50%;
  a {
    color: rgba(0, 0, 0, 0.87);
    opacity: 1;
    text-decoration: none;
    width: auto;
    overflow: hidden;
    font-size: 14px;
    font-weight: 500;
    box-sizing: border-box;
    min-height: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 1.75;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    display: flex;
    align-items: center;
    transition: background-color 0.2s ease;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
`
