import React from 'react'

import { StyledButton } from './Button.styles'

const Button = ({
  children,
  type,
  ...rest
}) => (
  <StyledButton
    as='button'
    type={type}
    {...rest}
  >
    {children}
  </StyledButton>
)

Button.defaultProps = {
  type: 'button',
  fontSize: [13.7],
  schema: 'secondary',
  size: 'medium'
}

export default Button
