import { mq } from './Layout.styles'
import styled from '@emotion/styled'
import Atom from './Atom.styles'

/* Default sizes change if you need */
export const StyledP = styled(Atom)(props => (
  mq({
    lineHeight: [1.71],
    color: ['rgba(0, 0, 0, 0.6)']
  })
))
