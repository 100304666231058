import { css } from '@emotion/core'
import styled from '@emotion/styled'
import ds from '../../theme'

import { mq } from './Layout.styles'
// import OpenMenuSVG from '../../images/menu.svg'
// import CloseMenuSVG from '../../images/close-24-px.svg'

export const head = css`
  background-color: ${ds.brand('primary')};
  background: #fff;
  height: 72px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`

export const wrapper = css`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 16px;
`

export const Toggle = styled('div')(props => (
  mq({
    width: 40,
    height: 40,
    flexShrink: 0,
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: '#f3f5f5',
    marginRight: 16,
    outline: 'none',
    transition: '.5s ease-in-out',
    transform: 'rotate(0deg)',
    position: 'relative'
  })
))

export const listItem = css(
  mq({
    paddingLeft: [20, 20, 28]
  })
)

export const logoLarge = css(
  mq({
    display: ['none', 'flex', 'flex', 'flex']
  })
)

export const logoSmall = css(
  mq({
    display: ['flex', 'none', 'none', 'none']
  })
)

export const Burguer = styled.div`
  position: absolute;
  width: 18px;
  height: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 18px;
    background: #6F7070;
    border-radius: 2px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  & span:nth-child(1) {
    top: 0px;
    transform-origin: left center;
  }

  & span:nth-child(2) {
    top: 5px;
    transform-origin: left center;
  }

  & span:nth-child(3) {
    top: 10px;
    transform-origin: left center;
  }

  &.open span:nth-child(1) {
    transform: rotate(45deg);
    top: -1px;
    left: 2px;
  }

  &.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  &.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 11.6px;
    left: 2px;
  }
`