import React from 'react'
import { Field, withFormik } from 'formik'
import axios from 'axios'
import H6 from './H6'

import MuiField from './../components/MuiField'
import Button from './../components/Button/Button'
import MuiSelect from '../components/Dropdown'

const FormikField = ({
  field,
  form,
  ...rest
}) => {
  return (
    <MuiField
      {...field}
      {...rest}
    />
  )
}

const FormikSelect = ({
  field,
  form,
  options,
  label,
  ...rest
}) => {
  return (
    <MuiSelect
      options={options}
      label={label}
      value={options.find(option => option.value === field.value)}
      onChange={(value) => {
        form.setFieldValue(field.name, value.value)
      }}
      isClearable={false}
      {...rest}
    />
  )
}

const validate = (value) => {
  let error
  if (!value) {
    error = 'Campo Obrigatório'
  }
  return error
}

const EnvieSuaMensagemForm = ({
  fields,
  values,
  isSubmitting,
  isValid,
  errors,
  submitForm,
  submitText,
  status
}) => {
  console.log(values)
  console.log(errors)
  return (
    <>
      {fields.map((field, i) => field.type !== 'select'
      ? (
        <Field
          key={`contact-field-${i}`}
          variant='outlined'
          label={field.label}
          name={field.name}
          placeholder={field.label}
          component={FormikField}
          validate={field.required && validate}
          multiline={field.multiline}
          rows={field.rows}
          mask={field.mask}
          style={{
            maxWidth: 296,
            marginBottom: 10
          }}
        />
      )
      : (
        <Field
          key={`contact-field-${i}`}
          label={field.label}
          name={field.name}
          placeholder={field.label}
          component={FormikSelect}
          options={field.options}
          validate={field.required && validate}
          multiline={field.multiline}
          rows={field.rows}
          mask={field.mask}
          style={{
            width: 328,
            marginBottom: 10,
            textAlign: 'left'
          }}
        />
      ))}
      <Button
        schema='secondary'
        color='white'
        variant='highEmphasis'
        width={['210px']}
        type='submit'
        disabled={isSubmitting || !isValid}
        style={{
          display: 'flex',
          border: 'none',
          marginTop: 14,
          margin: '0 auto',
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
          color: '#fff'
        }}
        onClick={() => submitForm()}
      >
        {submitText}
      </Button>
      {status === 'submitted' && (
        <H6
          on='onSurface'
          variant='highEmphasis'
          align='center'
          style={{
            marginTop: 16
          }}
        >
          Agradecemos seu<br />
          contato! Retornaremos<br />
          em breve.
        </H6>
      )}
      {status === 'error' && (
        <H6
          on='onSurface'
          variant='highEmphasis'
          align='center'
          style={{
            marginTop: 16
          }}
        >
          Desculpe-nos, não<br />
          foi possível enviar a<br />
          mensagem no momento.!
        </H6>
      )}
    </>
  )
}

EnvieSuaMensagemForm.defaultProps = {
  submitText: 'Enviar'
}

export default withFormik({
  mapPropsToValues: () => ({}),
  handleSubmit: async (values, { setSubmitting, setStatus, props }) => {
    setStatus('unsubmitted')
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.set(key, values[key])
      })
      setSubmitting(true)
      const response = await axios
        .post(`https://hostdime.com.br/wp/wp-json/contact-form-7/v1/contact-forms/${props.formId}/feedback`, formData)

      console.log(response.data)
      if (response.data.status === 'validation_failed') {
        throw new Error('Formulário inválido.')
      }

      setStatus('submitted')
      setSubmitting(false)
    } catch (e) {
      console.error(e)
      setStatus('error')
      setSubmitting(false)
    }
  }
})(EnvieSuaMensagemForm)